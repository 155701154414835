import React from 'react';

type SponsorAdProps = any;

export const SponsorAd: React.FC<SponsorAdProps> = (props: SponsorAdProps) => {
  return (
    <div className="px-gutter module-spacing" data-module="sponsorship-ad-50-50">
      <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 lg:gap-x-8 bg-ivory">
        <div className="col-span-full sm:col-span-6 900px:col-span-5  mx-5 900px:ml-14 pt-6 900px:pt-16 pb-8 900px:pb-20">
          <div className="mb-4 900px:mb-5 inline-flex bg-white bg-opacity-60 py-3 px-5 items-center flex-wrap space-y-2">
            <span className="type-lota text-dark-gold ml-2 mr-2">
              {props.seriesSponsorship?.sponsoredText ?? 'More on our sponsor'}
            </span>
            {props.seriesSponsorship?.sponsorLogo && (
              <div>
                <img
                  className="w-auto h-5 900px:h-7 mt-0"
                  src={props.seriesSponsorship.sponsorLogo.asset?.url || ''}
                  alt={props.seriesSponsorship.sponsorLogo.imageAltTag || ''}
                ></img>
              </div>
            )}
          </div>

          <h3 className="type-delta mb-3">{props.seriesSponsorship?.sponsorName}</h3>
          <p className="type-sm-p">{props.seriesSponsorship?.sponsorDescription}</p>
          {props.seriesSponsorship?.sponsorLink && (
            <div className="mt-5 lg:mt-7">
              <a href={props.seriesSponsorship?.sponsorLink || ''} className="button" target="_blank" rel="noreferrer">
                Learn More
              </a>
            </div>
          )}
        </div>
        {props.seriesSponsorship?.sponsorImage && (
          <div className="col-span-full sm:col-span-6 900px:col-start-7 900px:col-span-6 relative">
            <div className="900px:absolute w-full h-full">
              <img
                className="object-cover w-full h-full"
                src={props.seriesSponsorship?.sponsorImage?.asset?.url || ''}
                alt={props.seriesSponsorship?.sponsorImage?.asset?.altText || ''}
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
