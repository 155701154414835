import React from 'react';

import {SanitySeries} from 'types/generated';

type SeriesHeaderProps = Pick<SanitySeries, 'title' | 'heroImage' | 'seriesIntroduction' | 'seriesSponsorship'>;

export const SeriesHeader: React.FC<SeriesHeaderProps> = (props: SeriesHeaderProps) => {
  return (
    <section className="relative px-gutter module-spacing-top -top-[77px]" data-module="hero-series">
      {props.heroImage && (
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src={props.heroImage.asset?.url || ''}
            alt={props.heroImage.altText || ''}
          />
          <div className="absolute inset-0 bg-overlay"></div>
        </div>
      )}

      <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 lg:gap-8 relative z-10 module-spacing">
        <div className="col-span-full lg:col-span-8 lg:col-start-3 flex flex-col items-center text-center text-white">
          <a
            href={props.seriesSponsorship?.sponsorLink || ''}
            className="flex bg-white bg-opacity-10 py-3 px-4 justify-center items-center"
          >
            <span className="type-lota mx-2 text-white">{props.seriesSponsorship?.sponsoredText}</span>
            {props.seriesSponsorship && props.seriesSponsorship.sponsorLogo && (
              <img
                className="h-full"
                src={props.seriesSponsorship.sponsorLogo.asset?.url || ''}
                alt={props.seriesSponsorship.sponsorLogo.imageAltTag || ''}
              />
            )}
          </a>

          <h1 className="type-beta mt-7 lg:mt-11">{props.title}</h1>
          <div className="type-p mt-3 lg:mt-5">{props.seriesIntroduction}</div>
        </div>
      </div>
    </section>
  );
};
