import React from 'react';

import {GridCard} from 'src/components/editorial';
import {SEOWithQuery} from 'src/components/shared';

import {SanityEditorial, SanitySeries, Maybe} from 'types/generated';

import {Pagination} from '../../components/shared/Pagination';
import {SupportingContent} from '../editorial/SupportingContent';
import {InsightsNavigation} from '../insightsNavigation';
import {SeriesHeader} from './SeriesHeader';
import {SponsorAd} from './SponsorAd';

interface SeriesProps {
  series: SanitySeries;
  seriesArticles: [Maybe<SanityEditorial>];
  limit: number;
  currentPage: number;
  numPages: number;
  location: Location;
}

const SeriesTemplate: React.FC<SeriesProps> = ({series, seriesArticles, currentPage, limit, numPages}: SeriesProps) => {
  const Posts = seriesArticles.map((item, key) => {
    return (
      <GridCard
        key={key}
        cardColClasses={'grid-list_item'}
        title={item?.title}
        category={item?.editorialCategory ? item?.editorialCategory.title : item?.category}
        image={item?.heroImage}
        imageClasses={'grid-list_image'}
        contentClasses={'grid-list_content'}
        titleClasses={'type-epsilon mt-3'}
        slug={item?.slug?.current}
        eyebrowClasses={'grid-list_eyebrow '}
        editorialCategory={item?.editorialCategory}
        isPodcast={item?.isPodcast}
      />
    );
  });

  return (
    <>
      {currentPage >= 2 ? (
        <SEOWithQuery noIndex={true} />
      ) : (
        <SEOWithQuery
          title={series.seo?.seoMetaTitle || series.title || undefined}
          description={series?.seo?.seoDescription || undefined}
          image={series?.seo?.seoImage?.asset?.url || undefined}
        />
      )}

      <div className="bg-white">
        <InsightsNavigation bgColor={'bg-transparent'} />
        <SeriesHeader
          title={series.title}
          heroImage={series.heroImage}
          seriesIntroduction={series.seriesIntroduction}
          seriesSponsorship={series.seriesSponsorship}
        />
        <section className="px-gutter module-spacing" data-module="article-grid-list">
          <div className="grid-list">
            {Posts}
            {numPages != 1 && (
              <div className="col-span-full">
                <div className="flex items-center justify-center w-full flex-col md:flex-row">
                  <Pagination currentPage={currentPage} numPages={numPages} slug={`series/${series.slug?.current}`} />
                </div>
              </div>
            )}
          </div>
        </section>
        {series.seriesSponsorship && <SponsorAd seriesSponsorship={series.seriesSponsorship} />}
      </div>
    </>
  );
};

export default SeriesTemplate;
