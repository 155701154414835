import * as React from 'react';

import {useLocation} from '@reach/router';
import {Helmet} from 'react-helmet';
import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import SeriesTemplate from 'src/templates/series';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components';
import useSWR from 'swr';

const ErrorWrapper = styled('div').attrs({
  pt: 'L',
  pb: 'L',
})`
  background-color: ${({theme}) => theme.colors.white};
`;

interface SeriesDraftPageProps {
  params: {
    slug: string;
  };
}

const groqQuery = `
  *[
    _type=="series"
    && slug.current == $slug && (
      _id in path("drafts.**") || !defined(*[_id == "drafts." + ^._id][0])
    )
  ][0] {
    ...,
    heroImage {
      ...,
      asset->
    },
    seriesSponsorship {
      ...,
      sponsorLogo {
        ...,
        asset->
      },
      sponsorImage {
        ...,
        asset->
      }
    },
  }
`;

const SeriesDraftPage: React.FC<SeriesDraftPageProps> = ({params}: SeriesDraftPageProps) => {
  const {data, error} = useSWR([groqQuery, params.slug], (query, slug) => sanityClient.fetch(query, {slug}));
  const location = useLocation();
  if (error) {
    return (
      <ErrorWrapper>
        <Typography.H1>Error loading draft editorial:</Typography.H1>
        <Typography.P1>{JSON.stringify(error)}</Typography.P1>
      </ErrorWrapper>
    );
  }

  if (data === undefined) {
    return (
      <Container>
        <Typography.H2 mt="L" mb="L" color="white" textAlign="center">
          Loading draft preview
        </Typography.H2>
      </Container>
    );
  }

  if (data === null) {
    return (
      <Container>
        <Typography.P0 color="white">Sorry, we could not find a page with the provided slug</Typography.P0>
        <Typography.P0 color="white">
          If you believe this is an error, make sure you are logged into sanity.
        </Typography.P0>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SeriesTemplate
        location={location}
        series={data}
        seriesArticles={[null]}
        limit={0}
        currentPage={0}
        numPages={0}
      />
    </>
  );
};

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: 'black',
})``;

export default SeriesDraftPage;
